var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-badge",
    {
      staticClass: "item",
      attrs: { value: _vm.value.length, hidden: !_vm.value.length }
    },
    [
      _c(
        "el-select",
        {
          staticClass: "select-width",
          attrs: {
            multiple: "",
            filterable: "",
            remote: "",
            "collapse-tags": "",
            placeholder: "请输入关键词",
            "remote-method": _vm.remoteMethod,
            loading: _vm.loading,
            "multiple-limit": _vm.limit,
            "reserve-keyword": false
          },
          on: { change: _vm.handleSelect },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.cph, value: item[_vm.label] }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }