<template>
  <el-badge :value="value.length" :hidden="!value.length" class="item">
    <el-select
      class="select-width"
      v-model="value"
      multiple
      filterable
      remote
      collapse-tags
      placeholder="请输入关键词"
      :remote-method="remoteMethod"
      :loading="loading"
      @change="handleSelect"
      :multiple-limit="limit"
      :reserve-keyword="false"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.cph"
        :value="item[label]"
      ></el-option>
    </el-select>
  </el-badge>
</template>

<script>
/**
 * 选择车辆组件   模糊搜索 可多选|可与企业联动
 * 需要数据为车架号/车辆id
 * 车辆id：离线车辆统计
 */
import { queryVehicleFuzzyNew } from '@/api/lib/refreshAlarm.js'
import { queryVehicleByCph } from "@/api/lib/api.js"

export default {
  name: 'carTree',
  props: {
    label: {
      type: String,
      default: 'vehicleNo'
    },
    limit: {
      type: Number,
      default: 50
    },
    /**企业车辆 联动 */
    companyIdList: {
      type: Array,
      default: () => {
        return []
      }
    },
    //谢 2021.6.24修改 车辆查询是否需要公司id
    isNeedCompanyQuery: {
      type: Boolean,
      default: true,
    },
    //是否筛选全部类型终端车辆-ic卡使用统计
    isAllVehicle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: [],
      value: [],
      loading: false
    }
  },
  methods: {
    remoteMethod (query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          // let data = { cph: query, system: sessionStorage.getItem("system") }
          // 谢  修改 2021.06.09
          // 终端类型(跟随系统):0-808(adas) 1-905
          let data = { cph: query, deviceType: sessionStorage.getItem("system") }
          // if (this.companyIdList.length > 0)
          if (this.isNeedCompanyQuery) {
            data.companyId = this.companyIdList[0]
          }
          if (this.isAllVehicle) {
            queryVehicleByCph(data).then((res) => {
              if (res.code === 1000) {
                this.options = res.data
              }
            })
          } else {
            queryVehicleFuzzyNew(data).then((res) => {
              if (res.code === 1000) {
                this.options = res.data
              }
            })
          }

        }, 200)
      } else {
        this.options = []
      }
    },
    handleSelect () {
      this.$emit('getData', this.value)
    },
    resetTree () {
      this.value = []
      this.options = []
    }
  },
  created () { },
  mounted () {
    this.value = this.companyIdList
  }
}
</script>
