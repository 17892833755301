<template>
  <div class="analysisFormNew">
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      class="demo-form-inline"
      label-position="right"
      label-width="86px"
    >
      <!-- 公司车队： -->
      <el-form-item
        label="公司车队："
        v-show="showCompany"
        :prop="requireCompany ? 'companyIdList' : ''"
      >
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="false"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>
      <!-- 选择车辆： -->
      <el-form-item label="选择车辆：" v-show="showCar" :prop="isNeedRequire?'vehicleNos':''">
        <car-tree
          ref="carSearchTree"
          :label="label"
          @getData="getVehIds"
          :companyIdList="form.companyIdList"
          :isNeedCompanyQuery="isNeedCompanyQuery"
          :isAllVehicle="isAllVehicle"
        ></car-tree>
      </el-form-item>
      <!-- 司机姓名： -->
      <el-form-item label="司机姓名：" v-show="showDriver">
        <el-badge :value="form.driverIds.length" :hidden="!form.driverIds.length" class="item">
          <el-select
            v-model="form.credentialsNos"
            multiple
            filterable
            remote
            collapse-tags
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
            :multiple-limit="50"
            :reserve-keyword="false"
          >
            <el-option
              v-for="item in driverList"
              :key="item.id"
              :label="item.driverName"
              :value="item.credentialsNo"
            ></el-option>
          </el-select>
        </el-badge>
      </el-form-item>
      <!-- 用户姓名： -->
      <el-form-item :label="uerNameLabel" v-show="showUserName">
        <el-input v-model="form.userName" type="input" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="平台报警：" v-show="platformAlarmShow" prop="alarmSource">
        <el-select v-model="form.alarmSource" clearable placeholder>
          <el-option
            v-for="item in alarmSourceList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 选择时间： -->
      <el-form-item label="选择时间：" prop="time" v-show="showMultDay&&!showMonth">
        <el-date-picker
          cellClassName="timeRangePicker"
          v-model="form.time"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="computedTime?pickerOptions:sevenPickerOptions"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="选择日期：" prop="date" v-show="!showMultDay&&!showMonth">
        <el-date-picker
          v-model="form.date"
          placeholder="选择日期"
          type="date"
          :picker-options="chooseToday?pickerDateOptionsToday:pickerDateOptions"
        ></el-date-picker>
      </el-form-item>
      <!-- 选择月份 -->
      <el-form-item label="选择时间：" prop="month" v-show="showMonth">
        <el-date-picker v-model="form.month" type="month" placeholder="选择月"></el-date-picker>
      </el-form-item>
      <!-- 驾驶员： -->
      <el-form-item label="驾驶员：" v-show="showDriverName">
        <el-input v-model="form.driverName" type="input" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="类型：" v-show="faceShow">
        <el-select v-model="form.handleStatus" clearable placeholder="全部">
          <el-option label="未处理" :value="1"></el-option>
          <el-option label="已处理" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <!-- 先注释掉，不显示，默认值设置为不排除误报查询数据 -->
      <!-- <el-form-item label="排除误报：" v-show="mistakeShow" prop="mistake">
        <el-select v-model="form.mistake" clearable placeholder>
          <el-option
            v-for="item in mistakeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <!-- 迭代统计报表过滤报警类型 -->
      <el-form-item label="报警类型：" v-show="alarmTypeShow" prop="alarmType">
        <el-select v-model="form.alarmType" clearable placeholder>
          <el-option
            v-for="item in alarmTypeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label-width="0px">
        <el-button type="primary" size="small" @click="onSearch()">查询</el-button>
        <slot></slot>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/**
 * 报表查询form组件
 * 公司与车辆查询联动
 * 公司单选
 */
/**与之前的组件只有此处差异：车队不要系统参数，选择车辆修改为devicetype+cph参数 */
import carTree from "@/components/carTree/carSearchTreeNew.vue";
import companySelectForSearch from "@/components/treeSelect/companySelectForSearchNew.vue";
/**差异结束 */
import {
  getRangeDay,
  getXmonthToday,
  formatDate,
  formatDay,
  getStartDate,
  checkTimeLimitDay,
} from "@/common/utils/index";
// import { queryDriverByName } from "@/api/lib/api";
import { queryDriverByName } from "@/api/lib/bus-api.js";

export default {
  components: {
    carTree,
    companySelectForSearch,
  },
  props: {
    mistakeList: {
      type: Array,
      default: () => [],
    },
    timeLimit: {
      type: Number,
      default: 31,
    },
    formatDay: {
      type: Boolean,
      default: true,
    },
    showMonth: {
      type: Boolean,
      default: false,
    },
    /**车辆集合需要参数（车架号/车辆id) */
    label: {
      type: String,
      default: "vehicleNo",
    },
    showCar: {
      type: Boolean,
      default: true,
    },
    showDriverName: {
      type: Boolean,
      default: false,
    },
    faceShow: {
      type: Boolean,
      default: false,
    },
    mistakeShow: {
      type: Boolean,
      default: false,
    },//排除误报
    //报警类型
    alarmTypeShow: {
      type: Boolean,
      default: false,
    },
    alarmTypeList: {
      type: Array,
      default: () => [],
    },
    platformAlarmShow: {
      type: Boolean,
      default: false,
    },
    showMultDay: {
      type: Boolean,
      default: true,
    },
    //选择时间-只能选择一周节省服务器时间
    computedTime: {
      type: Boolean,
      default: true,
    },
    showUserName: {
      type: Boolean,
      default: false,
    },
    showCompany: {
      type: Boolean,
      default: true,
    },
    showDriver: {
      type: Boolean,
      default: false,
    },
    //能选择今天
    chooseToday: {
      type: Boolean,
      default: false,
    },
    //公司必填
    requireCompany: {
      type: Boolean,
      default: false,
    },
    //公司不显示车队
    isCompanyShrink: {
      type: Boolean,
      default: false,
    },
    //谢 2021.6.24修改 车辆查询是否需要公司id
    isNeedCompanyQuery: {
      type: Boolean,
      default: true,
    },
    //选择车辆是否是必填
    isNeedRequire: {
      type: Boolean,
      default: false
    },
    //是否筛选全部类型终端车辆
    isAllVehicle:{
      type: Boolean,
      default: false
    },
    //是否显示label为抽查人
    uerNameLabel:{
      type: String,
      default: '用户姓名：'
    }
  },

  watch: {
    isNeedRequire (v) {
      !v && this.$refs.form.clearValidate();
    }
  },
  data () {
    // 不能超前选择
    const validateDateForward = (rule, value, callback) => {
      if (this.chooseToday) {
        if (
          new Date() <= value[0] ||
          new Date() <= value[1]
        ) {
          callback(new Error('不能选择未来时间'))
        } else {
          callback();
        }
      } else {
        if (new Date(getStartDate()) <= value[0] || new Date(getStartDate()) <= value[1]) {
          callback(new Error("不能选择今天及未来时间"));
        } else {
          callback();
        }
      }
    };
    const validateDate1 = (rule, value, callback) => {
      if (!checkTimeLimitDay(value[0], value[1], this.timeLimit)) {
        callback(new Error(`只能查询${this.timeLimit}天内的数据`));
      } else {
        callback();
      }
    };
    return {
      terminalNos: [],
      driverList: [],
      loading: false,
      handleStatus: {},
      form: {
        time: getRangeDay(this.chooseToday),
        date: new Date().getTime() - 3600 * 1000 * 24,
        vehicleNos: [],
        driverIds: [],
        companyIdList: [],
        mistake: '0',
        alarmSource: '0',
        // month:new Date().getFullYear() + "-" + new Date().getMonth(),
        month: getXmonthToday(-1),
        alarmType:"",
        userName:"",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = getRangeDay(this.chooseToday)[1];
              const start = getRangeDay(this.chooseToday)[1] - 3600 * 1000 * 24 * 7;
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = getRangeDay(this.chooseToday)[1];
              const start = getRangeDay()[1] - 3600 * 1000 * 24 * 30;
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      sevenPickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = getRangeDay(this.chooseToday)[1];
              const start = getRangeDay(this.chooseToday)[1] - 3600 * 1000 * 24 * 6;
              picker.$emit("pick", [start, end]);
            },
          }
        ],
      },
      pickerDateOptions: {
        disabledDate (time) {          
          return time.getTime() > Date.now() - 3600 * 1000 * 24;
        },
      },
      pickerDateOptionsToday:{
        // 可以选择当天
        disabledDate (time) {          
          return time.getTime() > Date.now();
        },
      },
      formRules: {
        vehicleNos: [
          // { required: true, type: 'array', message: '请选择车辆', trigger: 'change' },
          {
            validator: (rule, value, callback) =>{
              if(this.form.userName){
                return callback();
              }else{
                if(value.length){
                  return callback();
                }else{
                  callback(new Error("请选择车辆"));
                }
              }
            },
            trigger: "change",equired: true, type: 'array',
          }
        ],
        companyIdList: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        time: [
          { required: true, message: "请选择时间范围", trigger: "blur" },
          { validator: validateDateForward, trigger: "change" },
          { validator: validateDate1, trigger: "change" },
        ],
        date: [{ required: true, message: "请选择日期", trigger: "blur" }],
        mistake: [{ required: true, message: "请选择误报", trigger: "blur" }],
        alarmSource: [
          { required: true, message: "请选择平台报警", trigger: "blur" }
        ],
      },
      alarmSourceList: [
        {
          dictValue: '所有',
          dictCode: '0',
        },
        {
          dictValue: '平台',
          dictCode: '1',
        },
        {
          dictValue: '终端',
          dictCode: '2',
        }
      ],
      
    };
  },

  methods: {
    // 获取车组数据
    getGroupIds (val) {
      this.$refs.form.clearValidate();
      this.form.companyIdList = val.length === 0 ? [] : [...val];
      //车辆选择数据清除
      this.form.vehicleNos = [];
      if (this.showCar) this.$refs.carSearchTree.resetTree();
    },
    // 获取车辆数据
    getVehIds (val) {
      this.$refs.form.clearValidate();
      this.form.vehicleNos = [...val];
    },
    // 模糊搜索
    remoteMethod (query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          // queryDriverByName({ driverName: query }).then((res) => {
          //   if (res.code === 1000) {
          //     this.driverList = res.data;
          //   }
          // });
          //谢修改2021.05.10
          queryDriverByName({ driverName: query }).then(res => {
            if (res.code == 1000) {
              this.driverList = res.data;
            }
          });
        }, 200);
      } else {
        this.driverList = [];
      }
    },

    onSearch () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = {};
          if (this.showMonth) {
            data = { ...this.form,
              beginTime:formatDay(this.form.month),
              endTime:formatDay(this.form.month)
            }
          } else {
            if (this.showMultDay) {
              if (this.formatDay) {
                data = {
                  ...this.form,
                  beginTime: formatDay(this.form.time[0]),
                  endTime: formatDay(this.form.time[1]),
                };
              } else {
                data = {
                  ...this.form,
                  beginTime: formatDate(this.form.time[0]),
                  endTime: formatDate(this.form.time[1]).split(" ")[0] + " 23:59:59",
                };
              }
            } else {
              data = {
                ...this.form,
                beginTime: formatDate(this.form.date),
                endTime: formatDate(this.form.date),
              };
            }
          }

          data.companyId =
            this.form.companyIdList.length > 0 ? this.form.companyIdList[0] : null;
          delete data.time;
          delete data.companyIdList;
          delete data.userName;
          delete data.driverName;
          delete data.driverIds;
          delete data.month;
          if (this.showUserName) data.userName = this.form.userName;
          if (this.showDriverName) data.driverName = this.form.driverName;
          if (this.showDriver) data.credentialsNos = this.form.credentialsNos;
          if (!this.mistakeShow) delete data.mistake
          if (!this.platformAlarmShow) delete data.alarmSource
          if (this.showMonth) data.month = this.form.month
          if(!this.alarmTypeShow) delete data.alarmType
          this.$emit("getFormVal", data);
        }
      });
    },
  },
  mounted () { },
};
</script>
